.gestion-materiales {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.gestion-materiales h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: bold;
    color: #333;
}

/* Estilo para el formulario de materiales */
.formulario-material {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.formulario-material label {
    font-size: 16px;
    font-weight: bold;
    color: #555;
}

.formulario-material input, .formulario-material select {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%; /* Abarcar todo el espacio disponible */
}

.formulario-material input:focus, 
.formulario-material select:focus {
    border-color: #007bff;
    outline: none;
}

.formulario-material button {
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

.formulario-material button:hover {
    background-color: #0056b3;
}

/* Estilo para los mensajes de error y éxito */
.mensaje-error, .mensaje-exito {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
}

.mensaje-error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.mensaje-exito {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

