.gestion-historial {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Nueva clase para alinear el botón y los filtros en la misma fila */
.acciones-filtros-historial {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.boton-agregar-historial {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.boton-agregar-historial:hover {
    background-color: #218838;
}

.tabla-historial {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.tabla-historial th, 
.tabla-historial td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.tabla-historial th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    font-size: 16px;
}

.tabla-historial td {
    background-color: #f9f9f9;
    font-size: 14px;
}

.boton-acciones {
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
    transition: background-color 0.3s ease;
}

.boton-acciones.editar {
    background-color: #ffc107;
    color: white;
}

.boton-acciones.editar:hover {
    background-color: #e0a800;
}

.boton-acciones.eliminar {
    background-color: #dc3545;
    color: white;
}

.boton-acciones.eliminar:hover {
    background-color: #c82333;
}

@media (max-width: 768px) {
    .gestion-historial {
        max-width: 100%;
    }
  
    .tabla-historial th, .tabla-historial td {
        font-size: 12px;
        padding: 8px;
    }
  
    .boton-agregar-historial {
        width: 100%;
        margin-bottom: 10px;
    }
  
    .boton-acciones {
        width: 100%;
        margin-bottom: 5px;
    }
}

.filtros-historial {
    display: flex;
    gap: 10px;
}

.filtros-historial input,
.filtros-historial select {
    padding: 8px;
    font-size: 16px;
}
