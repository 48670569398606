.editar-descuento-container {
    margin: auto;
    padding: 20px;
    width: 100%;
    max-width: 500px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  
  .formulario-editar-descuento label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .formulario-editar-descuento input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .formulario-editar-descuento button {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .formulario-editar-descuento button:hover {
    background-color: #218838;
  }
  
  .mensaje-error {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  
  .mensaje-exito {
    color: green;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  