.agregar-reporte-materiales-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .proyecto-row,
  .tecnico-row,
  .material-row {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .proyecto-row label,
  .tecnico-row label {
    flex: 0 0 150px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .proyecto-row input,
  .tecnico-row input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .nombre-proyecto,
  .nombre-material {
    flex: 0 0 auto;
    color: #777;
    font-size: 14px;
    font-style: italic;
  }
  
  .material-row input[type="text"],
  .material-row input[type="number"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .material-row .nombre-material {
    flex: 1;
    text-align: left;
    color: #777;
  }
  
  .material-row button,
  .proyecto-row button {
    background-color: #e74c3c;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .material-row button:hover,
  .proyecto-row button:hover {
    background-color: #c0392b;
  }
  
  .btn-buscar-material,
  .btn-buscar-proyecto {
    background-color: #ff5733;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .btn-buscar-material:hover,
  .btn-buscar-proyecto:hover {
    background-color: #c0392b;
  }
  
  .btn-agregar-material {
    background-color: #3498db;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease;
    width: 100%;
  }
  
  .btn-agregar-material:hover {
    background-color: #2980b9;
  }
  
  .btn-agregar-reporte {
    background-color: green;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease;
    width: 100%;
  }
  
  .btn-agregar-reporte:hover {
    background-color: #27ae60;
  }

  .mensaje-exito {
    text-align: center;
    color: green;
    font-size: 18px;
    margin-bottom: 20px;
    background-color: #e0ffe0;
    padding: 10px;
    border-radius: 5px;
  }
  