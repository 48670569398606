/* Estilos para el contenedor principal */
.container {
    margin-top: 20px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Título principal */
  h1 {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Botón Agregar Hoja de Materiales */
  .btn-success {
    display: block;
    width: 200px;
    margin: 0 auto 20px auto;
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
  }
  
  .btn-success:hover {
    background-color: #218838;
  }
  
  /* Estilos para la tabla */
  .table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: #fff;
    border-collapse: collapse;
  }
  
  .table thead {
    background-color: #007bff;
    color: white;
    text-align: left;
  }
  
  .table thead th {
    padding: 10px;
    font-size: 16px;
  }
  
  .table tbody td {
    padding: 10px;
    font-size: 14px;
    border-top: 1px solid #dee2e6;
  }
  
  .table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Estilos para los botones de acción (Editar, Eliminar) */
  .btn-warning, .btn-danger {
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .btn-warning {
    background-color: #ffc107;
    color: white;
  }
  
  .btn-warning:hover {
    background-color: #e0a800;
  }
  
  .btn-danger {
    background-color: #dc3545;
    color: white;
    margin-left: 10px;
  }
  
  .btn-danger:hover {
    background-color: #c82333;
  }
  
  /* Responsivo */
  @media (max-width: 768px) {
    .container {
      padding: 10px;
    }
  
    h1 {
      font-size: 24px;
    }
  
    .btn-success {
      width: 100%;
      margin-bottom: 15px;
    }
  
    .table thead {
      font-size: 14px;
    }
  
    .table tbody td {
      font-size: 12px;
    }
  
    .btn-warning, .btn-danger {
      padding: 5px 8px;
      font-size: 12px;
    }
  }
  