/* Estilos para el contenedor principal */
.gestion-tipo-documento {
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%; /* Ajusta el ancho para que esté centrado en la pantalla */
}

/* Título principal */
h2 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Estilos para el botón de agregar tipo de documento */
.boton-agregar-tipo-documento {
  padding: 10px 20px;
  background-color: #007bff; /* Azul para consistencia */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
}

.boton-agregar-tipo-documento:hover {
  background-color: #0056b3; /* Azul más oscuro al hacer hover */
}

/* Estilos para los botones de editar y eliminar */
.boton-editar {
  padding: 5px 10px;
  background-color: #ffc107; /* Color amarillo para Editar */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.boton-editar:hover {
  background-color: #e0a800; /* Hover más oscuro */
}

.boton-eliminar {
  padding: 5px 10px;
  background-color: #dc3545; /* Color rojo para Eliminar */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
}

.boton-eliminar:hover {
  background-color: #c82333; /* Hover más oscuro */
}

/* Estilos para la tabla */
.tabla-tipo-documento {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.tabla-tipo-documento th,
.tabla-tipo-documento td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.tabla-tipo-documento th {
  background-color: #007bff;
  color: white;
}

.tabla-tipo-documento tr:hover {
  background-color: #f1f1f1;
}

/* Estilos para el formulario de agregar y editar tipo de documento */
.formulario-tipo-documento {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.formulario-tipo-documento input {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
}

.formulario-tipo-documento button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.formulario-tipo-documento button:hover {
  background-color: #0056b3;
}

/* Estilo para el mensaje de éxito */
.mensaje-exito {
  color: green;
  font-size: 18px;
  margin-top: 10px;
  font-weight: bold;
}

/* Estilos responsivos */
@media screen and (max-width: 768px) {
  .gestion-tipo-documento {
    margin-left: 0; /* Eliminar el margen en pantallas pequeñas */
    width: 100%;
  }

  .formulario-tipo-documento input {
    width: 100%;
    margin-bottom: 10px;
  }

  .formulario-tipo-documento {
    flex-direction: column;
  }
}
