/* Estilos generales */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.estatus-proyecto {
  width: 97%;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

h1, h2, h3 {
  text-align: center;
  color: #333;
}

.busqueda {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.busqueda label {
  font-weight: bold;
  margin-right: 10px;
}

.busqueda input {
  padding: 8px;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.busqueda button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.busqueda button:hover {
  background-color: #0056b3;
}

/* Estilos específicos para botones del modal */
.busqueda button:nth-child(3) {
  background-color: #28a745;
}

.busqueda button:nth-child(3):hover {
  background-color: #218838;
}

/* Estilos para la tabla */
.tabla-materiales {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.tabla-materiales thead {
  background-color: #007bff;
  color: #fff;
}

.tabla-materiales th, .tabla-materiales td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: center;
}

.tabla-materiales tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.tabla-materiales tbody tr:hover {
  background-color: #f1f1f1;
}

/* Estilos específicos para los valores numéricos */
.tabla-materiales td {
  font-size: 14px;
}

.tabla-materiales th {
  font-size: 16px;
  font-weight: bold;
}

.tabla-materiales td:first-child {
  text-align: left;
}

.tabla-materiales td:last-child {
  font-weight: bold;
  color: #d9534f;
}

/* Estilos para mensajes de error */
.error {
  color: #d9534f;
  text-align: center;
  font-weight: bold;
}

/* Botón de descarga de Excel */
button.descargar-excel {
  display: block;
  margin: 20px auto;
  padding: 12px 25px;
  background-color: #17a2b8; /* Cambia el color de fondo a un tono más brillante */
  color: white;
  border: none;
  border-radius: 8px; /* Un borde más redondeado */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Añade sombra para darle profundidad */
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Añade una transición para suavizar los efectos */
}

button.descargar-excel:hover {
  background-color: #138496; /* Un tono más oscuro al hacer hover */
  transform: translateY(-3px); /* Levanta el botón ligeramente al pasar el ratón */
}


/* Estilos para el modal */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 1000;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.modal-header h3 {
  margin: 0;
}

.modal-close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

.modal-body {
  margin-top: 10px;
}

.modal-body table {
  width: 100%;
  border-collapse: collapse;
}

.modal-body th, .modal-body td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

/* Estilos para dispositivos móviles */
@media screen and (max-width: 768px) {
  .estatus-proyecto {
    width: 95%;
  }

  .tabla-materiales {
    font-size: 12px;
  }

  .busqueda input {
    width: 150px;
  }

  .busqueda {
    flex-direction: column;
  }

  .busqueda button {
    margin-left: 0;
    margin-top: 10px;
  }
}
