/* Contenedor general para los descuentos */
.gestion-descuentos {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.gestion-descuentos h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

/* Contenedor general de acciones: Botón + Filtros */
.acciones-descuentos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Estilos para los filtros */
.filtros-descuentos {
    display: flex;
    gap: 10px; /* Espacio entre los filtros */
}

.filtros-descuentos input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

/* Botón de agregar descuento */
.boton-agregar-descuento {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.boton-agregar-descuento:hover {
    background-color: #218838;
}

/* Tabla de descuentos */
.tabla-descuentos {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 16px;
}

.tabla-descuentos th, 
.tabla-descuentos td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.tabla-descuentos th {
    background-color: #007bff;
    color: white;
    text-transform: uppercase;
}

.tabla-descuentos td {
    text-align: center;
    color: #333;
}

/* Ajustar ancho de las columnas */
.tabla-descuentos th:nth-child(1),
.tabla-descuentos th:nth-child(2),
.tabla-descuentos th:nth-child(3),
.tabla-descuentos th:nth-child(4),
.tabla-descuentos th:nth-child(5),
.tabla-descuentos th:nth-child(6),
.tabla-descuentos th:nth-child(7) {
    width: 14%;
}

/* Botones de acciones */
.boton-acciones {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    color: white;
    margin-right: 10px; /* Añadido para espacio horizontal */
    margin-bottom: 10px; /* Añadido para espacio vertical */
    display: inline-block;
}

/* Alinear botones en fila */
.tabla-descuentos td {
    text-align: center;
    vertical-align: middle;
}

/* Contenedor flex para organizar los botones */
.contenedor-botones {
    display: flex;
    justify-content: center;
    gap: 10px; /* Espaciado horizontal entre botones */
}

.boton-acciones.editar {
    background-color: #ffc107;
}

.boton-acciones.editar:hover {
    background-color: #e0a800;
}

.boton-acciones.eliminar {
    background-color: #dc3545;
}

.boton-acciones.eliminar:hover {
    background-color: #c82333;
}

/* Ajustes para pantallas pequeñas */
@media screen and (max-width: 768px) {
    .acciones-descuentos {
        flex-direction: column;
        align-items: flex-start;
    }

    .filtros-descuentos {
        margin-top: 10px;
        flex-direction: column;
        width: 100%;
    }

    .filtros-descuentos input {
        width: 100%;
    }

    .boton-agregar-descuento {
        width: 100%;
        margin-bottom: 10px;
    }
}
