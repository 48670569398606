/* Título centrado */
.gestion-materiales h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

/* Contenedor de acciones: alinear botón a la izquierda y filtros a la derecha */
.acciones-materiales {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Filtros de código y nombre alineados a la derecha */
.filtro-codigo,
.filtro-nombre {
    padding: 8px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    width: 200px;
    box-sizing: border-box;
}

/* Botón de agregar material alineado a la izquierda */
.boton-agregar-material {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.boton-agregar-material:hover {
    background-color: #218838;
}

/* Tabla de materiales */
.tabla-materiales {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.tabla-materiales th, 
.tabla-materiales td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.tabla-materiales th {
    background-color: #007bff;
    color: white;
    text-transform: uppercase;
}

/* Ancho ajustado para la columna de código */
.tabla-materiales th:first-child, 
.tabla-materiales td:first-child {
    width: 80px;
    text-align: center;
}

/* Hover para filas de la tabla */
.tabla-materiales tr:hover {
    background-color: #f1f1f1;
}

/* Estilos para los botones de acciones (Editar y Eliminar) */
.boton-acciones {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    color: white;
}

/* Botón de editar */
.boton-acciones.editar {
    background-color: #ffc107;
}

.boton-acciones.editar:hover {
    background-color: #e0a800;
}

/* Botón de eliminar */
.boton-acciones.eliminar {
    background-color: #dc3545;
    margin-left: 10px;
}

.boton-acciones.eliminar:hover {
    background-color: #c82333;
}

/* Diseño responsivo para pantallas pequeñas */
@media screen and (max-width: 768px) {
    .gestion-materiales {
        padding: 15px;
    }

    .tabla-materiales th, 
    .tabla-materiales td {
        padding: 8px;
    }

    .boton-agregar-material {
        width: 100%;
        margin-bottom: 15px;
    }

    .boton-acciones {
        font-size: 12px;
        padding: 5px;
    }

    .filtro-codigo,
    .filtro-nombre {
        width: 100%;
        margin-top: 10px;
    }
}
