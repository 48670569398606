.gestion-produccion {
    margin: 20px;
    font-family: Arial, sans-serif;
}

.gestion-produccion h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

/* Contenedor de los filtros y el botón */
.filtros-boton-container {
    display: flex;
    justify-content: space-between; /* Para que los filtros y el botón se distribuyan correctamente */
    align-items: center;
    margin-bottom: 20px;
}

.filtros {
    display: flex;
    gap: 10px;
}

.filtros input, .filtros select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.boton-agregar {
    background-color: #28a745; /* Color verde */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.boton-agregar:hover {
    background-color: #218838;
}

.tabla-produccion {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
}

.tabla-produccion th,
.tabla-produccion td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.tabla-produccion th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
}

.tabla-produccion tr:nth-child(even) {
    background-color: #f2f2f2;
}

.tabla-produccion tr:hover {
    background-color: #ddd;
}

.tabla-produccion td {
    color: #333;
}

.boton-acciones {
    padding: 8px 12px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.boton-acciones.editar {
    background-color: #ffc107;
    color: white;
}

.boton-acciones.eliminar {
    background-color: #dc3545;
    color: white;
}

.boton-acciones.editar:hover {
    background-color: #e0a800;
}

.boton-acciones.eliminar:hover {
    background-color: #c82333;
}
