/* Estilos del modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-container {
    width: 90%;
    max-width: 600px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .modal-close {
    background-color: transparent;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: #333;
  }
  
  .modal-close:hover {
    color: #ff0000;
  }
  
  .modal-content {
    margin-top: 20px;
  }
  
  /* Estilos para los filtros */
  .filtros {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .input-filtro {
    flex: 1;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .input-filtro:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Estilos de la tabla */
  .project-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .project-table th,
  .project-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .project-table th {
    background-color: #f5f5f5;
    font-weight: bold;
  }
  
  .project-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .project-table tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Estilos para el mensaje cuando no hay proyectos */
  .project-table td[colspan="3"] {
    text-align: center;
    padding: 20px;
    font-style: italic;
    color: #999;
  }
  
  /* Responsividad para dispositivos móviles */
  @media (max-width: 768px) {
    .modal-container {
      width: 100%;
      max-width: 90%;
    }
  
    .filtros {
      flex-direction: column;
    }
    
    .input-filtro {
      width: 100%;
    }
  }
  