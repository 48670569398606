body {
    font-family: Arial, sans-serif;
  }
  
  .barra-navegacion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
    padding: 1rem;
  }
  
  .logo img {
    height: 50px;
  }
  
  .header {
    background-color: #eaf4fc;
    text-align: center;
    padding: 2rem 0;
  }
  
  .objetivos {
    display: flex;
    justify-content: space-around;
    padding: 2rem;
    background-color: #f1f1f1;
  }
  
  .card {
    background-color: #dff7ff;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    width: 30%;
  }
  
  .servicios {
    text-align: center;
    padding: 2rem;
  }
  
  .service-grid {
    display: flex;
    justify-content: space-around;
    padding: 1rem;
  }
  
  .service-grid div {
    width: 20%;
  }
  
  .service-grid img {
    width: 100%;
  }
  
  .pie-pagina {
    text-align: center;
    padding: 2rem;
    background-color: #f8f9fa;
    margin-top: 2rem;
  }
  
  .btn-iniciar-sesion {
    background-color: #007bff; /* Color azul */
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 5px;
  }

  .btn-iniciar-sesion:hover {
    background-color: #0056b3; /* Azul más oscuro en hover */
  }