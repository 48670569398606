/* Estilo para el contenedor de la vista de Iniciar Sesión */
.iniciar-sesion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 150px); /* Ajuste según la altura del encabezado y pie de página */
    padding: 20px;
  }
  
  .iniciar-sesion h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .iniciar-sesion form {
    background-color: #dde8f3;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .iniciar-sesion label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .iniciar-sesion input {
    width: 95%;
    padding: 10px 10px; /* Aumenta el padding en los lados */
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .iniciar-sesion button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .iniciar-sesion button:hover {
    background-color: #0056b3;
  }