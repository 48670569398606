.reporte-materiales-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Contenedor de filtros y botón */
  .filtros-y-boton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .filtros-container {
    display: flex;
    gap: 10px;
  }
  
  .filtros-container input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 250px;
  }
  
  /* Botón agregar reporte */
  .btn-agregar-reporte {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease;
  }
  
  .btn-agregar-reporte:hover {
    background-color: #218838;
  }
  
  /* Tabla de reportes */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  table th, table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
  }
  
  table th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  }
  
  table td {
    background-color: #ffffff;
  }
  
  table tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Botones de acciones */
  .acciones-btns {
    display: flex;
    justify-content: center;
    gap: 10px; /* Espacio entre los botones */
  }
  
  .btn-editar {
    background-color: #ffc107;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .btn-editar:hover {
    background-color: #e0a800;
  }
  
  .btn-eliminar {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .btn-eliminar:hover {
    background-color: #c82333;
  }
  
  /* Mensajes de error */
  .mensaje-error {
    color: #dc3545;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    padding: 10px;
    border-radius: 4px;
    margin-top: 15px;
    text-align: center;
  }
  