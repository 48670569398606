/* Estilos para el formulario de agregar/editar empleado */
.formulario-empleado {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .formulario-empleado input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%; /* Abarcar todo el espacio disponible */
  }
  
  .formulario-empleado button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .formulario-empleado button:hover {
    background-color: #0056b3;
  }
  
  /* Estilo para el mensaje de éxito */
  .mensaje-exito {
    color: green;
    font-size: 18px;
    margin-top: 10px;
    font-weight: bold;
  }
  
  /* Estilos responsivos */
  @media screen and (max-width: 768px) {
    .formulario-empleado input {
      width: 100%; /* Asegurar que los inputs sean responsivos */
    }
  
    .formulario-empleado button {
      width: 100%;
    }
  }
  