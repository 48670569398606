.editar-usuario {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color:#e6e6e6;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .formulario-editar-usuario {
    display: flex;
    flex-direction: column;
  }
  
  .formulario-editar-usuario label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .formulario-editar-usuario input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .formulario-editar-usuario button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .formulario-editar-usuario button:hover {
    background-color: #0056b3;
  }
  
  .mensaje-exito {
    color: green;
    font-size: 16px;
    margin-top: 10px;
  }

  .input-con-boton {
    display: flex;
    align-items: center;
  }
  
  .input-con-boton input {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
  }
  
  .input-con-boton button {
    padding: 8px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .input-con-boton button:hover {
    background-color: #0056b3;
  }
  