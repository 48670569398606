/* Estilos para el contenedor principal */
.gestion-empleados {
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%; /* Ajusta el ancho del contenedor para que no ocupe el 100% y se vea centrado */
}

/* Título principal */
h2 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Estilos para el botón de agregar empleado */
.boton-agregar-empleado {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
}

.boton-agregar-empleado:hover {
  background-color: #0056b3;
}

/* Estilos para los botones de editar y eliminar */
.boton-editar {
  padding: 5px 10px;
  background-color: #ffc107;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.boton-editar:hover {
  background-color: #e0a800;
}

.boton-eliminar {
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
}

.boton-eliminar:hover {
  background-color: #c82333;
}

/* Estilos para la tabla */
.tabla-empleados {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.tabla-empleados th,
.tabla-empleados td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.tabla-empleados th {
  background-color: #007bff;
  color: white;
}

.tabla-empleados tr:hover {
  background-color: #f1f1f1;
}

/* Estilos responsivos */
@media screen and (max-width: 768px) {
  .gestion-empleados {
    margin-left: 0; /* Eliminar el margen en pantallas pequeñas */
    width: 100%; /* Aseguramos que la tabla ocupe el ancho completo en pantallas pequeñas */
  }

  .tabla-empleados th, .tabla-empleados td {
    font-size: 14px; /* Ajustamos el tamaño del texto en pantallas pequeñas */
  }

  .boton-editar, .boton-eliminar {
    font-size: 12px; /* Reducimos el tamaño de los botones en pantallas pequeñas */
    padding: 5px 8px;
  }
}
