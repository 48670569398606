/* Estilos para el contenedor principal */
.gestion-usuarios {
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%; /* Ajusta el ancho para que esté centrado en la pantalla */
}

/* Título principal */
h2 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Estilos para el botón de agregar usuario */
.boton-agregar-usuario {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
}

.boton-agregar-usuario:hover {
  background-color: #0056b3;
}

/* Estilos para la tabla */
.tabla-usuarios {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.tabla-usuarios th,
.tabla-usuarios td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.tabla-usuarios th {
  background-color: #007bff;
  color: white;
}

.tabla-usuarios tr:hover {
  background-color: #f1f1f1;
}

/* Estilos para los botones de acciones */
.boton-acciones {
  padding: 5px 10px;
  background-color: #ffc107;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px; /* Añadir margen entre los botones */
}

.boton-acciones.eliminar {
  background-color: #dc3545;
}

.boton-acciones:hover {
  background-color: #e0a800;
}

.boton-acciones.eliminar:hover {
  background-color: #c82333;
}

/* Estilos responsivos */
@media screen and (max-width: 768px) {
  .gestion-usuarios {
    margin-left: 0; /* Eliminar margen en pantallas pequeñas */
    width: 100%; /* Asegurar que la tabla ocupe el ancho completo */
  }

  .tabla-usuarios th, .tabla-usuarios td {
    font-size: 14px; /* Ajuste de tamaño en pantallas pequeñas */
  }

  .boton-acciones {
    font-size: 12px; /* Reducir el tamaño de los botones en pantallas pequeñas */
    padding: 5px 8px;
  }
}
