.agregar-descuento-container {
  max-width: 600px;
  margin: 40px auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

h2 {
  text-align: center;
  color: #333333;
  margin-bottom: 20px;
}

.formulario-descuento {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.formulario-descuento label {
  font-weight: bold;
  color: #555555;
}

.formulario-descuento input[type="text"],
.formulario-descuento input[type="number"],
.formulario-descuento input[type="date"] {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
}

.proyecto-input-container,
.material-input-container {
  display: flex;
  align-items: center;
}

.proyecto-input-container input,
.material-input-container input {
  flex: 1;
}

.icon-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button:hover {
  background-color: #0056b3;
}

button[type="submit"] {
  background-color: #28a745;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

button[type="submit"]:hover {
  background-color: #218838;
}

.mensaje-error {
  color: #ff0000;
  text-align: center;
  margin-top: 10px;
}

.mensaje-exito {
  color: #28a745;
  text-align: center;
  margin-top: 10px;
}
