.formulario-usuario {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .formulario-usuario input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
  }
  
  .formulario-usuario button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .formulario-usuario button:hover {
    background-color: #0056b3;
  }
  
  .mensaje-exito {
    color: green;
    font-size: 18px;
    margin-top: 10px;
    font-weight: bold;
  }
  