/* Estilos generales para la gestión de proyectos */
.gestion-proyectos {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.gestion-proyectos h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

/* Botón de agregar proyecto */
.boton-agregar-proyecto {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.boton-agregar-proyecto:hover {
    background-color: #218838;
}

/* Contenedor de acciones de proyecto: Botón de agregar y filtros */
.acciones-proyectos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Estilos para los filtros */
.filtro-codigo,
.filtro-tarea,
.filtro-nombre,
.filtro-cliente {
    padding: 8px;
    margin: 5px 10px 5px 0; /* Margen ajustado */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    width: 200px;
    box-sizing: border-box;
}

@media screen and (max-width: 768px) {
    .acciones-proyectos {
        flex-direction: column;
        align-items: stretch;
    }

    .filtro-codigo,
    .filtro-tarea,
    .filtro-nombre,
    .filtro-cliente {
        width: 100%;
        margin: 10px 0; /* Margen para mejor separación en pantallas pequeñas */
    }

    .boton-agregar-proyecto {
        width: 100%;
        margin-bottom: 15px;
    }
}

/* Tabla de proyectos */
.tabla-proyectos {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.tabla-proyectos th, 
.tabla-proyectos td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.tabla-proyectos th {
    background-color: #007bff;
    color: white;
    text-transform: uppercase;
}

/* Ancho ajustado para la columna de código */
.tabla-proyectos th:first-child, 
.tabla-proyectos td:first-child {
    width: 80px;
    text-align: center;
}

/* Hover para filas de la tabla */
.tabla-proyectos tr:hover {
    background-color: #f1f1f1;
}

/* Estilos para los botones de acciones (Editar y Eliminar) */
.boton-acciones {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    color: white;
    transition: background-color 0.3s ease;
}

/* Botón de editar */
.boton-acciones.editar {
    background-color: #ffc107;
}

.boton-acciones.editar:hover {
    background-color: #e0a800;
}

/* Botón de eliminar */
.boton-acciones.eliminar {
    background-color: #dc3545;
    margin-left: 10px;
}

.boton-acciones.eliminar:hover {
    background-color: #c82333;
}

/* Mensaje de éxito */
.mensaje-exito {
    color: green;
    font-size: 16px;
    margin-top: 10px;
}

/* Diseño responsivo para pantallas pequeñas */
@media screen and (max-width: 768px) {
    .gestion-proyectos {
        padding: 15px;
    }

    .tabla-proyectos th, 
    .tabla-proyectos td {
        padding: 8px;
    }

    .boton-agregar-proyecto {
        width: 100%;
        margin-bottom: 15px;
    }

    .boton-acciones {
        font-size: 12px;
        padding: 5px;
    }
}
