/* Contenedor principal */
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Título principal */
h1 {
    text-align: center;
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
}

/* Estilos generales de los inputs y selects */
.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    color: #555;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

/* Estilos específicos para el textarea */
textarea {
    resize: vertical;
    min-height: 100px;
}

/* Estilos para los botones */
.btn {
    display: inline-block;
    font-size: 16px;
    padding: 10px 20px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.btn:hover {
    background-color: #0056b3;
}

.btn-danger {
    background-color: #dc3545;
}

.btn-danger:hover {
    background-color: #c82333;
}

.btn-primary {
    background-color: #28a745;
}

.btn-primary:hover {
    background-color: #218838;
}

.btn-info {
    background-color: #17a2b8;
}

.btn-info:hover {
    background-color: #138496;
}

.btn-success {
    background-color: #28a745;
    margin-top: 20px;
}

.btn-success:hover {
    background-color: #218838;
}

/* Grupo horizontal (para inputs que están uno al lado del otro) */
.group-horizontal {
    display: flex;
    gap: 20px;
}

.group-horizontal .form-group {
    flex: 1;
    margin-bottom: 0;
}

/* Estilos para los divs con botones e inputs en la misma línea */
.input-with-button {
    display: flex;
    gap: 10px;
}

.input-row {
    display: flex;
    gap: 10px;
}

/* Alineación de los inputs de materiales */
.material-group {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    align-items: center;
}

.material-group input {
    flex: 1;
}

.material-group .btn-danger {
    padding: 10px 15px;
    flex-shrink: 0;
}

/* Asegurar que los botones tengan un tamaño adecuado */
.material-group .btn {
    padding: 10px 15px;
    white-space: nowrap;
}

/* Estilos para la notificación de éxito */
.alert {
    padding: 15px;
    margin-top: 20px;
    border-radius: 5px;
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

/* Responsividad */
@media (max-width: 768px) {
    .group-horizontal,
    .material-group {
        flex-direction: column;
        align-items: stretch;
    }

    .input-with-button {
        flex-direction: column;
    }

    .material-group .btn-danger {
        margin-top: 10px;
        width: 100%;
    }
}
