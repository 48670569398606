.editar-reporte-materiales-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.proyecto-row,
.tecnico-row,
.material-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

.proyecto-row label,
.tecnico-row label {
  flex: 0 0 150px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.proyecto-row input,
.tecnico-row input,
.material-row input[type="text"],
.material-row input[type="number"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.nombre-proyecto,
.nombre-material {
  flex: 1;
  text-align: left;
  color: #777;
  font-size: 14px;
}

.btn-buscar {
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
}

.btn-buscar:hover {
  color: #0056b3;
}

.btn-guardar {
  background-color: green;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s ease;
  width: 100%;
}

.btn-guardar:hover {
  background-color: #27ae60;
}

.mensaje-exito {
  text-align: center;
  color: green;
  font-size: 18px;
  margin-bottom: 20px;
  background-color: #e0ffe0;
  padding: 10px;
  border-radius: 5px;
}

.mensaje-error {
  text-align: center;
  color: red;
  font-size: 18px;
  margin-bottom: 20px;
  background-color: #ffe0e0;
  padding: 10px;
  border-radius: 5px;
}
