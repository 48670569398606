.vista-tecnico {
  text-align: center;
  margin: 20px;
}

.vista-tecnico h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

/* Estilo para el formulario de subir foto */
.vista-tecnico form {
  display: flex;
  justify-content: center; /* Centrar el formulario horizontalmente */
  align-items: center;     /* Alinear los elementos verticalmente */
  gap: 10px;               /* Espacio entre los elementos */
  margin-bottom: 20px;
}

/* Estilo para el campo de selección de archivo */
.vista-tecnico input[type="file"] {
  flex: 2;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Estilo para el campo de tarea */
.vista-tecnico input[type="text"] {
  flex: 2;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Estilo para el botón de subir foto */
.vista-tecnico button {
  flex: 1;
  padding: 10px 20px;
  background-color: #28a745; /* Color verde consistente con el resto del proyecto */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.vista-tecnico button:hover {
  background-color: #218838; /* Efecto hover para el botón */
}

/* Mensaje de éxito o error */
#message {
  font-size: 16px;
  margin-bottom: 20px;
  color: green;
}

/* Estilo para la galería de fotos */
.galeria-fotos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.foto {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.foto img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.foto p {
  font-size: 14px;
  color: #333;
}

/* Estilo para los enlaces de descarga */
.foto a {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.foto a:hover {
  text-decoration: underline;
}

/* Estilo para el botón de eliminar */
.foto button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #dc3545; /* Color rojo consistente con el resto del proyecto */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.foto button:hover {
  background-color: #c82333;
}

/* Estilo para el campo de filtrar por tarea */
.vista-tecnico input[type="text"][placeholder="Filtrar por tarea"] {
  width: 50%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.mensaje-exito {
  color: green;
  font-size: 16px;
  margin-top: 10px;
}