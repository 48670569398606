.formulario-proyecto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.formulario-proyecto input,
.formulario-proyecto textarea,
.formulario-proyecto select {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%; /* Abarcar todo el espacio disponible */
    box-sizing: border-box; /* Para que no sobresalga en algunos casos */
}

/* Estilo personalizado para el select */
.formulario-proyecto select {
    appearance: none; /* Remueve el estilo predeterminado del navegador */
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%23007bff" d="M2 0L0 2h4z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 10px 10px;
    color: #333;
    cursor: pointer;
}

.formulario-proyecto button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.formulario-proyecto button:hover {
    background-color: #0056b3;
}

/* Estilo para el mensaje de éxito */
.mensaje-exito {
    color: green;
    font-size: 18px;
    margin-top: 10px;
    font-weight: bold;
}
