.modal-content {
    overflow-y: auto;
  }
  
  .material-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .material-table th, .material-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .material-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .modal-filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .modal-filter-input {
    width: 48%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    max-height: 80%;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  