.admin-layout {
  display: flex;
  height: 100vh; /* Aseguramos que todo el contenido ocupe el 100% de la altura */
}

.menu {
  background-color: #0057A6;
  padding: 20px;
  color: white;
  height: 100vh; /* El menú ocupará toda la altura de la ventana */
  width: 250px;
  transition: width 0.3s ease;
  position: fixed; /* Fijamos el menú para que siempre esté visible */
  top: 0;
  left: 0;
  overflow-y: auto; /* Permitir desplazamiento si el menú excede la pantalla */
  z-index: 1; /* Aseguramos que el menú siempre esté por encima del contenido */
}

.menu.collapsed {
  width: 80px;
}

.menu-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.menu-title {
  font-size: 24px;
  margin-left: 10px;
  color: white;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px; /* Dejar espacio suficiente entre los elementos */
}

.menu li {
  margin-bottom: 20px;
}

.menu a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.menu a svg {
  margin-right: 10px;
}

.menu a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
}

.menu.collapsed a {
  justify-content: center;
}

.content {
  flex: 1;
  margin-left: 250px; /* Deja espacio para el menú lateral */
  padding: 20px;
  overflow-y: auto; /* Permitir que el contenido a la derecha sea desplazable */
  transition: margin-left 0.3s ease; /* Transición suave para cuando el menú colapsa */
}

.content.collapsed {
  margin-left: 80px; /* Ajustar cuando el menú está colapsado */
}

.content h1 {
  font-size: 2.5em;
  font-weight: bold;
}

.content p {
  font-size: 1.2em;
}

.toggle-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.toggle-button:hover {
  color: #ccc;
}

/* Estilo para el botón de cerrar sesión */
.logout-item {
  margin-top: auto; /* Empuja el botón de cerrar sesión hacia abajo */
}

.btn-logout {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.btn-logout:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.btn-logout svg {
  margin-right: 10px;
}

