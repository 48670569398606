/* Estilos generales para la página de editar material */
.editar-materiales {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #e6e6e6;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.editar-materiales h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

/* Estilos para el formulario de editar material */
.formulario-editar-materiales {
    display: flex;
    flex-direction: column;
}

.formulario-editar-materiales label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

/* Estilo para los campos de entrada de texto */
.formulario-editar-materiales input,
.formulario-editar-materiales select {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
}

/* Estilo específico para el campo de código que es de solo lectura */
.formulario-editar-materiales input[disabled] {
    background-color: #f0f0f0;
    cursor: not-allowed;
    color: #666;
}

/* Estilo para los botones */
.formulario-editar-materiales button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.formulario-editar-materiales button:hover {
    background-color: #0056b3;
}

/* Estilo para el mensaje de éxito */
.mensaje-exito {
    color: green;
    font-size: 16px;
    margin-top: 10px;
    font-weight: bold;
}

/* Estilo para el mensaje de error */
.mensaje-error {
    color: red;
    font-size: 16px;
    margin-top: 10px;
    font-weight: bold;
}

/* Estilo para campos que tienen un botón adicional (ej. input con botón para buscar) */
.input-con-boton {
    display: flex;
    align-items: center;
}

.input-con-boton input {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
}

.input-con-boton button {
    padding: 8px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.input-con-boton button:hover {
    background-color: #0056b3;
}

/* Estilos responsivos para dispositivos móviles */
@media (max-width: 768px) {
    .editar-materiales {
        padding: 15px;
    }

    .formulario-editar-materiales input,
    .formulario-editar-materiales select {
        font-size: 14px;
    }

    .formulario-editar-materiales button {
        font-size: 14px;
        padding: 8px;
    }
}
