.agregar-produccion-container {
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  .agregar-produccion-container h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .formulario-produccion {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .formulario-produccion div {
    margin-bottom: 15px;
  }
  
  .formulario-produccion label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .formulario-produccion input,
  .formulario-produccion select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .formulario-produccion input:read-only {
    background-color: #e9ecef;
  }
  
  .proyecto-input-container {
    display: flex;
    align-items: center;
  }
  
  .proyecto-input-container input {
    flex: 1;
  }
  
  .icon-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .icon-button:hover {
    background-color: #0056b3;
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  button[type="submit"]:hover {
    background-color: #218838;
  }
  
  .mensaje-error {
    color: #dc3545;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
  }
  
  .mensaje-exito {
    color: #28a745;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
  }
  